// Write your custom css/scss here

.modal-backdrop {
    opacity: 0.5;
}

.pointer {
    cursor: pointer;
}

.game_name {
    background: #f3f3f3;
    text-align: center;
    padding: 7px;
    margin-bottom: 15px;
}

.my_games {
    display: grid;
    grid-template-columns: repeat(3, 32%);
    justify-content: space-between;
    grid-row-gap: 10px;
    & .my_game_item{
        border: 1px #e6e6e6 solid;
        border-radius: 5px;
        min-height: 55px;
        padding: 10px;
        text-align: center;
        box-shadow: 0px 0px 10px 4px #e8e8e8;
        position: relative;
        & button{
            border: 0;
            background: #c71616;
            position: absolute;
            right: 0;
            top: 0;
            border-top-right-radius: 5px;
            color: #fff;
            font-size: 11px;
            padding: 4px 10px;
            z-index: 2;
        }
        & strong {
            font-size: 16px;
        }
        & span {
            display: block;
            font-size: 12px;
        }
    }
}

.my_times {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    & .my_time_item{
        border: 1px #ddd solid;
        border-radius: 5px;
        min-height: 45px;
        padding: 10px;
        text-align: center;
        & span {
            display: block;
        }
    }
}

.playing_content {
    background: #fff;
    margin: 0 auto;
    padding: 23px;
    border: 0;
    box-shadow: 0px 0px 5px #bbb;
}

.error_list {
    background: #ce6464;
    padding: 10px 12px;
    border-radius: 5px;
    color: #fff;
    margin-bottom: 10px;
}

.error_list ul {
    margin: 0;
    padding: 0 17px;
}

.step_info {
    padding: 12px 0;
    text-align: center;
    text-transform: uppercase;
    position: relative;
}

.step_info.selected {
    border: 1px #ddd solid;
    border-radius: 5px;
    margin-bottom: 11px;
}

.edit_icon {
    position: absolute;
    right: 15px;
}

.tiny_icon {
    width: 15px;
    height: 16px;
    cursor: pointer;
}

.logo_auth {
    width: 100px;
    object-fit: contain;
}

.numbers_input {
    display: flex;
    margin-bottom: 8px;
    & input{
        width: 100%;
        margin: 0 10px 0px 0px;
        padding: 10px;
        border: 1px #bdbdbd solid;
        border-radius: 5px;
        text-align: center;
    }
}

input.is_invalid {
    border: 1px red solid;
}

.my_game_item.selected {
    border: 1px #3F51B5 solid !important;
}

.is-error {
    border: 1px #ff3466 solid !important;
}

td.hiddenRow {
    padding: 0;
    border-top: none;
}

.inner_table {
    padding: 15px 0px;
    margin: 0 auto;
    text-align: center;
    background: #f7f7f7;
}

.grand_total {
    text-align: center;
    background: #f1f1f1;
    width: 24%;
    padding: 12px 0;
    float: right;
}

.datepicker {
    width: auto !important;
    max-width: max-content !important;
}

.btn-app {
    padding: 10px;
}

.btn-login {
    padding: 12px;
    background: #09488d;
    border: 1px #09488d solid;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    &:hover{
        background: #09488d;
        border: 1px #09488d solid;
        color: #000;
    }
}

.modal_error {
    color: #bd0f0f;
    text-align: center;
    padding-bottom: 8px;
}

.balance_danger {
    background: #f3cfd8;
}

.result_list {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    width: 100%;
    grid-column-gap: 15px;
    grid-row-gap: 10px;
    & .result_list_item {
        background: #fff;
        box-shadow: 0px 0px 5px #b9b1b1;
        & .result_list_item_header {
            background: #bad018;
            color: #000;
            padding: 10px;
            text-align: center;
            font-size: 16px;
        }
        & .result_list_items_bg {
            background: #fff;
            padding: 0px 18px;
            & strong{
                color: #000;
                text-align: center;
                display: block;
                font-size: 30px;
            }
            & .result_list_items{
                border-bottom: 1px #ddd solid;
                padding: 11px 0;
                &:last-child{
                    border-bottom: none;
                }
                & ul{
                    padding: 0;
                    margin: 0;
                    & li {
                        list-style: none;
                    }
                }
            }
        }
    }
}

.number_list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    & .number_list_item {
        background: #fff;
        display: flex;
        justify-content: space-between;
        border: 1px #ddd solid;
        padding: 3px;
    }
}

.total_sell {
    font-weight: bold;
    padding: 10px 0 0;
    text-align: right;
}

.comission_super {
    color: #bb0c0c;
    font-size: 10px;
    font-weight: bold;
    position: relative;
    top: -8px;
}

.bg_login {
    background: #09488d;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    & img {
        width: 50%;
    }
}

.login_name {
    margin-top: 10px;
    & span{
        display: block;
        text-align: center;
        color: #fff;
        font-weight: bold;
        line-height: 20px;
        font-size: 21px;
        text-transform: uppercase;
    }
}

.ui-logo {
    font-weight: 900;
    font-size: 25px;
    letter-spacing: -1px;
    color: #22332c;
    padding-bottom: 15px;
    &:hover{
        color: #22332c;
    }
}

.report_lists_games {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 7px;
    align-items: center;
    justify-content: space-between;
    & .report_lists_game {
        text-align: center;
        border-radius: 5px;
        padding: 7px;
        cursor: pointer;
    }
    & .inactive{
        border: 1px #ddd solid;
    }
    & .active{
        border: 1px #B8D219 solid;
    }
}

.filter_list_head {
    display: grid;
    grid-template-columns: 20% repeat(5, 1fr);
    text-align: right;
}

.filter_list_head_2 {
    display: grid;
    grid-template-columns: 20% repeat(7, 1fr);
    text-align: right;
}

.filter_list_head_global {
    display: grid;
    grid-template-columns: 20% repeat(4, 1fr);
    text-align: right;
}

.filter_list_item_global {
    display: grid;
    grid-template-columns: 20% repeat(4, 1fr);
    padding: 10px 0;
    border-bottom: 1px #dedcdc solid;
    text-align: right;
    font-size: 13px;
    &:last-child{
        border-bottom: none;
    }
}

.filter_list_foot_global {
    background: #f7f7f7;
    padding: 5px 0;
    display: grid;
    grid-template-columns: 20% repeat(4, 1fr);
    text-align: right;
    font-size: 13px;
}

.filter_list_item {
    display: grid;
    grid-template-columns: 20% repeat(5, 1fr);
    padding: 10px 0;
    border-bottom: 1px #dedcdc solid;
    text-align: right;
    font-size: 13px;
    &:last-child{
        border-bottom: none;
    }
}

.filter_list_item_2 {
    display: grid;
    grid-template-columns: 20% repeat(7, 1fr);
    padding: 10px 0;
    border-bottom: 1px #dedcdc solid;
    text-align: right;
    font-size: 13px;
    &:last-child{
        border-bottom: none;
    }
}

.w-first {
    width: 20%;
}

.filter_list {
    margin-bottom: 60px;
}

.filter_list_foot {
    background: #f7f7f7;
    padding: 5px 0;
    display: grid;
    grid-template-columns: 20% repeat(5, 1fr);
    text-align: right;
    font-size: 13px;
}

.filter_list_foot_2 {
    background: #f7f7f7;
    padding: 5px 0;
    display: grid;
    grid-template-columns: 20% repeat(7, 1fr);
    text-align: right;
    font-size: 13px;
}

.filter_list_items {
    margin-bottom: 20px;
}

#printMe {
    padding: 20px 0;
    width: 219px;
    display: none;
}

.print_logo{
    text-align: center;
}

.print_logo img {
    width: 70px;
    margin: 0 auto;
}

.print_detail{
    text-align: center;
    padding-top: 6px;
}
.print_buy {
    text-align: center;
    padding-top: 6px;
}

.print_buy span{
    display: block;
}

.print_head {
    display: grid;
    grid-template-columns: 65% 10%;
    justify-content: space-between;
    margin-top: 5px;
    padding: 0 15px;
}

.print_head strong {
    font-size: 13px;
}

.print_head_total{
    font-size: 16px !important;
}

.print_list_item {
    display: grid;
    grid-template-columns: 65% 5%;
    justify-content: space-between;
    padding: 5px 15px;
    border-bottom: 1px #d6d6d6 solid;
    font-size: 15px;
}

.print_thanks {
    padding-top: 10px;
    text-align: center;
}

.print_thanks span{
    display: block;
}

.print_number {
    text-align: center;
    padding-top: 10px;
}

.print_number span{
    font-size: 11px;
}

.number_listing {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin-bottom: 20px;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.number_playing {
    text-align: center;
    box-shadow: 0 0px 9px 0px #bfbebe;
    padding: 5px 0;
    border-radius: 5px;
    position: relative;
    & .circle {
        background: #09488d;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: bold;
        margin: 0 auto;
        color: #fff;
    }
    & span {
        font-size: 12px;
        margin-top: 4px;
        display: block;
    }
    & button {
        border: 0;
        font-size: 11px;
        position: absolute;
        right: 0;
        background: #f15867;
        border-top-right-radius: 5px;
        color: #fff;
        top: 0;
        padding: 2px 7px;
    }
}

.restrcited {
    background: #ff3246b5 !important;
    color: #fff;
}

.block_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.circle_selected {
    background: #e4e4e4;
    padding: 4px 10px;
    font-size: 12px;
    border-radius: 5px;
}

.circle_active {
    background: #bad018;
}

.header_img {
    position: absolute;
    bottom: 0;
    right: 180px;
    & img {
        width: 90px;
    }
}

.btn-app:disabled {
    background: #969696;
    border: 1px #969696 solid;
}

.duende_resultados {
    text-align: right;
    margin-top: 50px;
    width: 90%;
    & img{
        width: 150px;
        height: 150px;
    }
}

.title_results_header {
    text-align: center;
}

.results {
    width: 40%;
    margin: 20px auto;
    & .result_index {
        margin-bottom: 20px;
        text-align: center;
        font-size: 20px;
        & .result_lists {
            display: grid;
            grid-auto-flow: column;
            grid-column-gap: 20px;
            grid-row-gap: 20px;
            margin-top: 15px;
            & .result_lists_item {
                background: #ffffff;
                padding: 15px;
                box-shadow: 0px 0px 5px #d0d0d0;
                text-align: center;
                font-weight: bold;
                font-size: 17px;
                position: relative;
                & .circle_red {
                    width: 20px;
                    height: 20px;
                    background: #bf1111;
                    border-radius: 50%;
                    position: absolute;
                    left: 57px;
                    right: 0;
                    margin: 0 auto;
                    top: 18px;
                }
                & .circle_white {
                    width: 20px;
                    height: 20px;
                    background: #fff;
                    box-shadow: 0px 0px 5px #908f8f;
                    border-radius: 50%;
                    position: absolute;
                    left: 57px;
                    right: 0;
                    margin: 0 auto;
                    top: 18px;
                }
            }
        }
    }
}

.btn_manual_add {
    border: 0;
    background: #bad018;
    width: 60px;
    height: 40px;
    position: relative;
    display: none;
}

.btn-copy {
    position: absolute;
    right: 11px;
    bottom: 10px;
}

.table-max {
    max-height: 500px;
    overflow: scroll;
}

@media screen and (max-width: 768px){
    .my_games {
        display: grid;
        grid-template-columns: repeat(2, 45%);
        & .my_games .my_game_item {
            max-height: 100px;
        }
    }

    .my_times {
        grid-template-columns: repeat(3, 1fr);
    }

    .header_img img {
        width: 43px;
    }
    .btn_manual_add {
        display: block;
    }
}